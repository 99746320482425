(<template>
  <div class="capacity-page">
    <calendar-navigation :allowed-view-types="['day', 'week']"
                         class="capacity-page__navigation" />
    <filters :lang-to="currentLangTo"
             :favourite="currentRelation"
             :qualifications="currentQualifications"
             :gender="currentGender"
             :city="currentCity"
             :stand-by="currentStandBy"
             class="capacity-page__filters"
             @catchlangto="catchLangTo"
             @catchfavourite="catchFavourite"
             @catchname="catchName"
             @catchqualifications="catchQualifications"
             @catchgender="catchGender"
             @catchcity="catchCity"
             @catchstandby="catchStandby"
             @clearfilters="clearFilters" />
    <div :class="{'in-progress': progressActive, '': resourceInterpretersList.length <= 5}"
         class="capacity-page__wrapper is-progress-bar">
      <timeline-calendar v-if="resourceInterpretersList.length"
                         :active-date="currentDate"
                         :resources="resourceInterpretersList"
                         :resources-title="resourcesTitle"
                         :events="availabilityEvents"
                         :view="currentView"
                         class="capacity-page__calendar"
                         @catchcalendarinstance="catchCalendar"
                         @periodselected="handlePeriodSelection"
                         @startprogress="progressActive = true"
                         @stopprogress="progressActive = false" />
      <div v-if="!resourceInterpretersList.length"
           class="capacity-page__filters-note">{{ $gettext('There are currently no interpreters matching these criteria.') }}</div>
    </div>
    <events-description :events-list="eventDescriptionsList"
                        :events-description="eventDescriptionsText"
                        :is-selected-event="false"
                        class="capacity-page__events-description" />
    <sk-pagination v-if="totalPage > 1"
                   :current="+currentPage"
                   :total="+totalPage"
                   class="capacity-page__pagination"
                   @prev="toPrevPage"
                   @next="toNextPage"
                   @newpage="toNewPage" />
  </div>
</template>)

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
  import helpers from '@/helpers';
  import CalendarNavigation from '@/components/calendars/CalendarNavigation';
  import TimelineCalendar from '@/components/capacity/TimelineCalendar';
  import EventsDescription from '@/components/shared_components/availability/EventsDescription';
  import Filters from '@/components/capacity/Filters';

  export default {
    asyncData({store, route}) {
      let promisesArr = '';

      if (!store.state.CapacityStore.interpreters && route.query.page) {
        promisesArr = [...promisesArr, store.dispatch('CapacityStore/getInterpreters', {
          ...route.query,
          ...{
            langFrom: '3'
          }
        })];
      }
      if (!store.state.selectsData.language || !store.state.selectsData.language.length) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'language')];
      }
      if (!store.state.selectsData.qualification || !store.state.selectsData.qualification.length) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'qualification')];
      }

      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    components: {
      filters: Filters,
      'calendar-navigation': CalendarNavigation,
      'timeline-calendar': TimelineCalendar,
      'events-description': EventsDescription
    },
    data() {
      return {
        progressActive: false,
        currentDate: this.$route.query.date || this.$moment().format('YYYY-MM-DD'),
        currentView: this.$route.query.view || 'day',
        currentPage: this.$route.query.page || 1,
        currentLangFrom: '3',
        currentLangTo: this.$route.query.langTo || '',
        currentRelation: !!this.$route.query.relation,
        currentName: this.$route.query.nameSearch || '',
        currentCity: this.$route.query.location || '',
        currentStandBy: this.$route.query.standby || false,
        currentGender: this.$route.query.gender || '',
        currentQualifications: this.$route.query.qualification
          ? this.$route.query.qualification.map
            ? this.$route.query.qualification.map((item) => +item)
            : [+this.$route.query.qualification]
          : [],
        calendarInstance: '',
        eventDescriptionsList: [
          [[], 'event'],
          [[], 'job'],
          [[], 'offtime'],
          [[], 'travelToTime'],
          [[], 'travelFromTime'],
          [[], 'standby']
        ]
      };
    },
    computed: {
      ...mapGetters('CapacityStore', [
        'resourceInterpreters'
      ]),
      ...mapState('CapacityStore', {
        availabilityEvents: ({availabilityEvents}) => availabilityEvents,
        interpreterData: ({interpreters}) => interpreters || {}
      }),
      resourcesTitle() {
        return this.$gettext('Interpreters');
      },
      resourceInterpretersList() {
        return this.resourceInterpreters.map((interpreter = {}) => {
          const profilePageLink = this.$router.resolve(this.$makeRoute({
            name: 'BuyerUserProfile',
            params: {
              id: interpreter.id
            }
          })).href;
          const genderText = helpers.getJobInfo.getGenderName(this, interpreter.sex);
          const cityText = interpreter.city ? `${interpreter.city} | ` : '';
          const interpreterInfoHTML = `<div class="capacity-page__cell-light-text">${cityText}${genderText}</div>`;
          const qualificationText = this.getQualification(interpreter.skills, this.currentLangTo);
          const qualificationHTML = qualificationText ? `<div class="capacity-page__cell-light-text">${qualificationText}</div>` : '';
          const titleHTML = `<div class="capacity-page__cell-text">${interpreter.title}</div>`;
          const nameHTML = `<div>${titleHTML}${interpreterInfoHTML}${qualificationHTML}</div>`;
          // Choose correct icon
          const blockedIcon = '<span class="capacity-page__cell-fav blocked-icon"></span>';
          const favouriteIcon = '<span class="capacity-page__cell-fav favourite-icon"></span>';
          const relationIcon = interpreter.relation === 'favourite'
            ? favouriteIcon : interpreter.relation === 'blocked'
              ? blockedIcon : '';
          const avatarStyle = interpreter.avatar ? `style='background-image: url("${interpreter.avatar}")'` : '';
          const avatarHTML = `<div ${avatarStyle} class="capacity-page__cell-avatar">${relationIcon}</div>`;
          const bookButtonText = this.$gettext('Book');
          const bookInterpreterLink = this.$router.resolve(
            this.$makeRoute({
              name: 'BuyerPostInterpretation',
              query: {
                interpreterid: interpreter.id
              }
            })
          ).href;
          const infoLink = `<a target="_blank" href="${profilePageLink}" class="capacity-page__cell-wrapper">${avatarHTML}${nameHTML}</a>`;
          const bookLink = interpreter.bookable
            ? `<a target="_blank" class="sk-btn sk-btn--primary capacity-page__cell-btn" href="${bookInterpreterLink}">${bookButtonText}</a>`
            : '';

          interpreter.rawHTMLTitle = `<div class="fc-cell-text capacity-page__cell-wrapper">${infoLink}${bookLink}</div>`;

          return interpreter;
        });
      },
      clientWidth() {
        return this.$store.state.GlobalStore.clientWidth || 768;
      },
      totalPage() {
        return this.interpreterData.pageTotal || 0;
      },
      showPagination() {
        return this.resourceInterpreters.length && this.totalPage > 1;
      },
      eventDescriptionsText() {
        return this.$gettext('*Standby interpreter - means that the interpreter is available for assignment and will be confirmed immediately');
      }
    },
    watch: {
      clientWidth() {
        if (this.clientWidth < 768) {
          this.setCalendarView('day');
        }
      },
      $route(newVal) {
        this.currentPage = newVal.query.page || 1;
        this.currentView = newVal.query.view || 'day';
        this.currentDate = newVal.query.date || this.$moment().format('YYYY-MM-DD');

        this.fetchData();
      }
    },
    methods: {
      ...mapActions('CapacityStore', [
        'getAvailabilityEvents'
      ]),
      ...mapMutations('CapacityStore', [
        'clearAvailabilityEvents'
      ]),
      getQualification(skills = [], languageId) {
        if (languageId) {
          const qualificationId = skills.find((skill = {}) => {
            return skill.langFromId == this.currentLangFrom && skill.langToId == this.currentLangTo;
          })?.qualificationId;
          return this.getQualificationText(qualificationId, languageId);
        } else {
          const qualifications = skills
            .filter((skill) => skill.langFromId == this.currentLangFrom)
            .map((skill) => {
              return this.getQualificationText(skill.qualificationId, skill.langToId);
            });

          return qualifications.join('<br>');
        }
      },
      getQualificationText(qualificationId, languageId) {
        const qualificationText = helpers.getJobInfo.getQualificationName(this, {
          id: qualificationId,
          showShortName: true
        })
          || this.$gettext('Unknown qualification');
        const languageText = this.$getLangName(languageId)
          || this.$gettext('Unknown language');

        return `${languageText} - ${qualificationText}`;
      },
      catchCalendar(inst) {
        this.calendarInstance = inst;
      },
      toPrevPage() {
        this.currentPage -= 1;

        this.submitFilters();
      },
      toNewPage(page) {
        this.currentPage = +page;

        this.submitFilters();
      },
      toNextPage() {
        this.currentPage += 1;

        this.submitFilters();
      },
      submitFilters() {
        const requiredQueryParams = {
          view: this.currentView || 'day',
          date: this.currentDate || this.$moment().format('YYYY-MM-DD'),
          page: this.currentPage
        };
        const additionalQueryParams = {};

        if (this.currentLangTo) {
          additionalQueryParams.langTo = this.currentLangTo;
        }
        if (this.currentRelation) {
          additionalQueryParams.relation = !!this.currentRelation;
        }
        if (this.currentName) {
          additionalQueryParams.nameSearch = this.currentName;
        }
        if (this.currentCity) {
          additionalQueryParams.location = this.currentCity;
        }
        if (this.currentQualifications && this.currentQualifications.length) {
          additionalQueryParams.qualification = this.currentQualifications;
        }
        if (this.currentGender) {
          additionalQueryParams.gender = this.currentGender;
        }
        if (this.currentStandBy) {
          additionalQueryParams.standby = this.currentStandBy;
        }
        this.$router.push(this.$makeRoute({
          name: 'BuyerCapacity',
          query: {
            ...requiredQueryParams,
            ...additionalQueryParams
          }
        }));
      },
      handlePeriodSelection(data = {}) {
        const startTime = this.$moment(data.startStr);
        const finishTime = this.$moment(data.endStr);

        if (startTime.isBefore(this.$moment())) {
          this.$store.commit('InfoModalStore/setInfoModal', {
            text: this.$gettext('Sorry, you can\'t create new assignment in the past.')
          });
        } else {
          const interpreterUid = data.resource?.id || '';
          const interpreterName = data.resource?.title || '';

          this.openBookingModal({
            interpreterUid,
            interpreterName,
            startTime,
            finishTime
          });
        }
      },
      openBookingModal({interpreterName, startTime, finishTime, interpreterUid}) {
        const startTimeMoment = this.$moment(startTime);
        const finishTimeMoment = this.$moment(finishTime);
        const timeStartTime = startTimeMoment.format('HH:mm');
        const timeFinishTime = finishTimeMoment.format('HH:mm');
        const time = `${timeStartTime}-${timeFinishTime}`;
        const monthNameStartTime = this.$helperData.getMonthNames(this)[startTimeMoment.month()].slice(0, 3);
        const dateStartTime = `${startTimeMoment.date()} ${monthNameStartTime} ${startTimeMoment.year()}`;
        const monthNameFinishTime = this.$helperData.getMonthNames(this)[finishTimeMoment.month()].slice(0, 3);
        const dateFinishTime = `${finishTimeMoment.date()} ${monthNameFinishTime} ${finishTimeMoment.year()}`;
        const timeTemplate = this.$gettext('%{time} on %{date}');
        const template = dateStartTime === dateFinishTime
          ? this.$gettext('Would you like to direct book <b>%{interpreterName}</b> at %{time} on %{date}')
          : this.$gettext('Would you like to direct book <b>%{interpreterName}</b> from %{startTime} to %{finishTime}');

        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          classes: ['sk-modal--new', 'capacity-page__modal'],
          data: {
            title: this.$gettext('Book a specific interpreter'),
            text: this.$gettextInterpolate(
              template, {
                interpreterName,
                time,
                date: dateStartTime,
                startTime: this.$gettextInterpolate(timeTemplate, {
                  time: timeStartTime,
                  date: dateStartTime
                }),
                finishTime: this.$gettextInterpolate(timeTemplate, {
                  time: timeFinishTime,
                  date: dateFinishTime
                })
              }
            ),
            context: this,
            modalCallback: this.openJobPage,
            callbackParams: {
              interpreterid: interpreterUid,
              startTime: startTimeMoment.format(),
              finishTime: finishTimeMoment.format(),
            },
            btnTexts: {
              actionBtnText: this.$gettext('Yes'),
              cancelBtnText: this.$gettext('No')
            }
          }
        });
      },
      openJobPage(query) {
        window.open(
          this.$router.resolve(
            this.$makeRoute({
              name: 'BuyerPostInterpretation',
              query
            })
          ).href,
          '_blank'
        );
      },
      setCalendarView(view) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            view: view || this.$route.query.view || 'day',
            date: this.$route.query.date || this.$moment().format('YYYY-MM-DD'),
            page: this.$route.query.page || 1,
          }
        });
      },
      fetchData() {
        this.$store.commit('CapacityStore/clearStore');
        this.$store.commit('GlobalStore/startProgress');
        this.$options.asyncData({
          store: this.$store,
          route: this.$route
        }).then(() => {
          this.$store.commit('GlobalStore/stopProgress');
          this.fetchAvailability();
        });
      },
      catchLangTo(value) {
        this.currentLangTo = value;
        this.currentPage = 1;
        this.submitFilters();
      },
      catchQualifications(value) {
        this.currentQualifications = value;
        this.currentPage = 1;
        this.submitFilters();
      },
      catchFavourite(value) {
        this.currentRelation = value;
        this.currentPage = 1;
        this.submitFilters();
      },
      catchGender(value) {
        this.currentGender = value;
        this.currentPage = 1;
        this.submitFilters();
      },
      catchName(value) {
        this.currentName = value;
        this.currentPage = 1;
        this.submitFilters();
      },
      catchCity(value) {
        this.currentCity = value;
        this.currentPage = 1;
        this.submitFilters();
      },
      catchStandby(value) {
        this.currentStandBy = value;
        this.currentPage = 1;
        this.submitFilters();
      },
      clearFilters() {
        this.currentPage = 1;
        this.currentRelation = false;
        this.currentGender = '';
        this.currentQualifications = [];
        this.currentLangTo = '';
        this.currentName = '';
        this.currentStandBy = false;
        this.currentCity = '';
        this.submitFilters();
      },
      fetchAvailability() {
        const promisesArr = [];

        this.progressActive = true;
        this.clearAvailabilityEvents();
        this.resourceInterpreters.forEach((resource) => {
          promisesArr.push(
            this.getAvailabilityEvents({
              params: {
                date_from: this.currentView === 'week'
                  ? this.$moment(this.currentDate).startOf('isoWeek').format('YYYY-MM-DD')
                  : this.$moment(this.currentDate).format('YYYY-MM-DD'),
                date_to: this.currentView === 'week'
                  ? this.$moment(this.currentDate).endOf('isoWeek').format('YYYY-MM-DD')
                  : this.$moment(this.currentDate).format('YYYY-MM-DD')
              },
              uid: resource.id
            })
          );
        });
        Promise.all(promisesArr)
          .then(() => {
            this.progressActive = false;
          });
      }
    },
    mounted() {
      if (this.dataPromise) {
        this.dataPromise
          .then(() => {
            this.fetchAvailability();
          });
      } else if (this.resourceInterpreters.length) {
        this.fetchAvailability();
      }
      if (!this.$route.query.view || !this.$route.query.date) {
        this.setCalendarView(this.clientWidth < 768 ? 'day' : '');
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.commit('CapacityStore/clearStore');
      next();
    }
  };
</script>

<style>
  .capacity-page__modal .confirmation__btn {
    min-width: 70px;
  }

  .capacity-page__cell-light-text {
    color: #a7abbd;
    font-size: 10px;
  }

  .capacity-page__cell-wrapper {
    display: flex !important;
    align-items: center;
  }

  .capacity-page__cell-avatar {
    position: relative;
    display: inline-block;
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    background-image: var(--image-user-icon);
    background-position: 50% 50%;
    background-clip: content-box;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  .capacity-page__cell-fav::before {
    width: 15px;
    height: 15px;
  }

  .capacity-page__cell-btn {
    width: auto;
    min-width: 40px;
    margin-left: auto;
    padding: 0 5px;
  }
</style>

<style scoped>
  .capacity-page {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 30px 20px 20px;
  }

  .capacity-page__wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    transition: all 0.25s ease-in-out;
  }

  .capacity-page__calendar {
    flex-grow: 1;
    background-color: white;
  }

  .capacity-page__filters {
    padding-top: 5px;
    padding-bottom: 11px;
  }

  .capacity-page__filters-note {
    padding: 30px 10px;
    text-align: center;
  }

  .capacity-page__navigation {
    margin: 0 -15px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .capacity-page__events-description {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .capacity-page__wrapper.in-progress::after {
    margin-top: -1px;
    background-color: #f4f5f7;
  }

  .capacity-page__pagination {
    margin: auto auto 0;
  }

  @media (max-width: 767px) {
    .capacity-page__calendar {
      min-height: 450px;
    }
  }
</style>
