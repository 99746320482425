(<template>
  <section class="filters">
    <div class="filters__wrapper">
      <div class="filters__field filters__input-wrapper">
        <sk-input :placeholder="nameText"
                  :icon="'search'"
                  :preselected-value="localName"
                  class="filters__input filters__input--right-icon"
                  @fieldvaluechanged="setLocalName"
                  @enterpressed="catchName" />
        <button class="sk-btn sk-btn--default filters__input-btn"
                @click="catchName">{{ $gettext('Search') }}</button>
      </div>
      <div class="filters__field filters__input-wrapper">
        <sk-input :placeholder="cityText"
                  :icon="'search'"
                  :preselected-value="localCity"
                  class="filters__input filters__input--right-icon"
                  @fieldvaluechanged="setLocalCity"
                  @enterpressed="catchCity" />
        <button class="sk-btn sk-btn--default filters__input-btn"
                @click="catchCity">{{ $gettext('Search') }}</button>
      </div>
      <sk-select :items-list="languagesList"
                 :preselected-value="langTo"
                 :default-value="languagesText"
                 class="filters__field filters__select"
                 @csvaluechanged="catchLangTo" />
      <sk-multiselect :items-list="qualificationsList"
                      :preselected-value="qualifications"
                      :default-value="qualificationsText"
                      class="filters__field filters__select filters__select--multiple"
                      @checkboxeschanged="catchQualifications" />
      <sk-select :items-list="favouritesList"
                 :preselected-value="favouriteValue"
                 :default-value="favouriteText"
                 class="filters__field filters__select"
                 @csvaluechanged="catchFavourite" />
      <sk-select :items-list="gendersList"
                 :preselected-value="gender"
                 :default-value="genderText"
                 class="filters__field filters__select"
                 @csvaluechanged="catchGender" />
      <sk-checkbox :preselected-value="standBy"
                   :item-text="standByText"
                   class="filters__field"
                   @checkboxvaluechanged="catchStandBy" />
    </div>
    <button class="sk-btn sk-btn--white filters__btn"
            @click="clearFilters">{{ $gettext('Clear') }}</button>
  </section>
</template>)

<script>
  export default {
    props: {
      favourite: {
        type: Boolean
      },
      langTo: {
        type: [String, Number],
        default: ''
      },
      qualifications: {
        type: Array,
        default: () => []
      },
      name: {
        type: String,
        default: ''
      },
      city: {
        type: String,
        default: ''
      },
      gender: {
        type: [String, Number],
        default: ''
      },
      standBy: Boolean
    },
    data() {
      return {
        localName: this.name || '',
        localCity: this.city || ''
      };
    },
    computed: {
      languages() {
        return this.$store.state.selectsData.language || [];
      },
      languagesText() {
        return this.$gettext('Language');
      },
      languagesList() {
        const langsList = [...this.languages];

        langsList.unshift({id: 'all', name: this.$gettext('All')});
        return langsList;
      },
      qualificationsList() {
        return this.$store.getters.interpretationQualifications;
      },
      qualificationsText() {
        return this.$gettext('Qualification(s)');
      },
      favouriteValue() {
        return this.favourite ? 1 : 0;
      },
      favouritesList() {
        return [{
          id: 0,
          name: this.$gettext('All interpreters')
        }, {
          id: 1,
          name: this.$gettext('Only favorite')
        }];
      },
      favouriteText() {
        return this.$gettext('Show favorite');
      },
      nameText() {
        return this.$gettext('Name');
      },
      cityText() {
        return this.$gettext('City');
      },
      genders() {
        return this.$helperData.getSelectGenders(this) || [];
      },
      gendersList() {
        const gendersList = [...this.genders];

        gendersList.unshift({id: 'all', name: this.$gettext('All')});
        return gendersList;
      },
      genderText() {
        return this.$gettext('Gender');
      },
      standByText() {
        return this.$gettext('Standby interpreters');
      }
    },
    methods: {
      catchLangTo(value) {
        this.$emit('catchlangto', value === 'all' ? '' : value);
      },
      catchQualifications(value) {
        this.$emit('catchqualifications', value);
      },
      catchFavourite(value) {
        this.$emit('catchfavourite', value == 1);
      },
      catchName() {
        this.$emit('catchname', this.localName);
      },
      catchCity() {
        this.$emit('catchcity', this.localCity);
      },
      catchGender(value) {
        this.$emit('catchgender', value === 'all' ? '' : value);
      },
      catchStandBy(value) {
        this.$emit('catchstandby', value);
      },
      setLocalName(value) {
        this.localName = value;
      },
      setLocalCity(value) {
        this.localCity = value;
      },
      clearFilters() {
        this.localName = '';
        this.localCity = '';
        this.$emit('clearfilters');
      }
    }
  };
</script>

<style>
  .filters__select .sk-select {
    background-color: #fff;
  }

  .filters__select--multiple .sk-select__list {
    width: calc(100% + 85px);
  }

  .filters__input .sk-input__input-wrapper {
    background-color: #fff;
  }

  .filters__input--right-icon .sk-input__field-left-icon {
    right: 0;
    left: auto;
    width: 23px;
  }

  .filters__input--right-icon .sk-input__input {
    padding-right: 23px;
    padding-left: 10px;
  }
</style>

<style scoped>
  .filters {
    display: flex;
    align-items: center;
  }

  .filters__wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-items: center;
    margin: -5px -5px;
  }

  .filters__field {
    max-width: 180px;
    margin: 5px 5px;
  }

  .filters__select {
    width: 100%;
  }

  .filters__input--right-icon {
    padding: 0;
  }

  .filters__input-wrapper {
    display: flex;
  }

  .filters__input {
    margin-bottom: 0;
  }

  .filters__input-btn {
    width: auto;
    min-width: 55px;
    padding: 0 5px;
    border-radius: 0 3px 3px 0;
  }

  .filters__btn {
    width: auto;
    min-width: 60px;
    padding: 0 10px;
  }
</style>
