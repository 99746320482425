(<template>
  <timeline-calendar v-if="resources && showCalendar"
                     :key="calendarRenderId"
                     :active-date="activeDate"
                     :selectable="selectable"
                     :resources="resources"
                     :resources-title="resourcesTitle"
                     :view="view"
                     :events="eventsList"
                     :column-header="columnHeader"
                     :class="[`timeline-calendar--${view}`]"
                     class="timeline-calendar"
                     @catch-calendar-instance="catchCalendar"
                     @period-selected="selectPeriod" />
</template>)

<script>
  import Vue from 'vue';

  export default {
    props: {
      events: {
        type: Array,
        default() {
          return [];
        }
      },
      resourcesTitle: {
        type: String,
        default: ''
      },
      resources: {
        type: Array,
        default() {
          return [];
        }
      },
      view: {
        type: String,
        default: ''
      },
      activeDate: {
        type: [String, Object],
        default: ''
      },
      columnHeader: {
        type: [Boolean, undefined],
        default: undefined
      }
    },
    data() {
      return {
        calendarRenderId: '',
        showCalendar: false
      };
    },
    computed: {
      selectable() {
        return true;
      },
      mainProgressActive() {
        return this.$store.state.GlobalStore.mainProgressActive;
      },
      eventsList() {
        let eventsList = [];

        this.events.forEach((events = {}) => {
          const jobs = (events.jobs || []).map((item = {}) => {
            item.title = '';
            item.classNames = [
              item.sessionType || ''
            ];
            return item;
          });
          const privateEvents = (events.events || []).map((item = {}) => {
            const isStandByEvent = item.eventType == '3';
            const isPrivateEvent = item.eventType == '1';
            const standByTypeText = item.sessionType === 'on_site'
              ? this.$gettext('(in-person or videoroom)')
              : this.$gettext('(phone or video)');

            item.title = isPrivateEvent
              ? this.$gettext('Private event')
              : isStandByEvent
                ? `${this.$gettext('StandBy interpreter')} ${standByTypeText}`
                : '';
            item.classNames = [
              isPrivateEvent
                ? 'private-event'
                : 'stand-by'
            ];
            item.selectOverlap = isStandByEvent;
            return item;
          });
          const offTimes = (events.offTimes || []).map((item = {}) => {
            item.title = this.$gettext('N/A');
            item.classNames = [
              'off-time'
            ];
            item.selectOverlap = false;
            return item;
          });
          const travelTimes = ([].concat(events.travelTimes[0] || [], events.travelTimes[1] || [])).map((item) => {
            item.title = '';
            item.classNames = [
              'travel-time'
            ];
            item.selectOverlap = false;
            return item;
          });
          const data = [...jobs, ...privateEvents, ...offTimes, ...travelTimes];
          const mappedData = data.map((event = {}) => {
            return {
              ...event,
              start: event.startTime || event.start_time || '',
              end: event.finishTime || event.finish || '',
              data: event,
              resourceId: events.resourceId,
            };
          });

          eventsList = [...eventsList, ...mappedData];
        });
        return eventsList;
      }
    },
    watch: {
      $route() {
        setTimeout(() => {
          this.renderCalendar();
        }, 0);
      },
      resources() {
        setTimeout(() => {
          this.renderCalendar();
        }, 0);
      },
      events() {
        setTimeout(() => {
          this.renderCalendar();
        }, 0);
      },
      mainProgressActive() {
        setTimeout(() => {
          this.renderCalendar();
        }, 0);
      }
    },
    methods: {
      renderCalendar() {
        setTimeout(() => {
          if (this.events) {
            Vue.component('timeline-calendar', () => import('@/components/capacity/TimelineCalendarCore'));
            this.calendarRenderId = Math.random();
            this.showCalendar = true;
          }
        }, 0);
      },
      navDateClick(date) {
        this.$router.push({
          name: this.$route.name,
          query: {
            view: 'day',
            date: this.$moment(date)
              .format('YYYY-MM-DD')
          }
        });
      },
      selectPeriod(data) {
        this.$emit('periodselected', data);
      },
      catchCalendar(inst) {
        this.$emit('catchcalendarinstance', inst);
      }
    },
    mounted() {
      this.renderCalendar();
    }
  };
</script>

<style>
  .timeline-calendar--week .fc-widget-content:nth-child(24n + 1) {
    border-left: 3px solid #ddd;
  }

  .timeline-calendar--week .fc-widget-content:nth-child(1) {
    border-left: 1px solid #ddd;
  }

  .timeline-calendar--week .fc-widget-header:nth-child(24n + 1) {
    border-left: 3px solid #ddd;
  }

  .timeline-calendar--week .fc-widget-header:nth-child(1) {
    border-left: 1px solid #ddd;
  }

  .timeline-calendar--week .fc-widget-header[colspan="24"] {
    border-left: 3px solid #ddd;
  }

  .timeline-calendar .fc-timeline-event:focus {
    box-shadow: 0 0 2px 1px #3af;
  }

  .timeline-calendar .fc-view-container {
    margin-top: -1px;
  }

  .timeline-calendar .fc-timeline-event:hover {
    z-index: 1000 !important;
    color: #000;
    box-shadow: inset 3px 0 0 0 rgba(0, 34, 102, 0.2);
  }

  .timeline-calendar .fc-timeline-event:active {
    box-shadow: none;
  }

  .timeline-calendar .fc-timeline-event.travel-time {
    border: solid 1px #ccc;
    border-radius: 4px;
    background-color: #e5e5e5;
    color: #000;
  }

  .timeline-calendar .fc-timeline-event.off-time {
    border: solid 1px #cecce7;
    border-radius: 4px;
    background-color: #fff;
    background-image: url(~@assets/imgs/undefined_imgs/striped-bg.svg);
    background-position: 50% 50%;
    background-size: auto 60px;
    color: #3f43ad;
    font-weight: bold;
  }

  .timeline-calendar .fc-timeline-event.private-event {
    border: solid 1px #fd6;
    border-radius: 4px;
    background-color: #ffecb0;
    color: #333;
    font-weight: bold;
  }

  .timeline-calendar .fc-timeline-event.stand-by {
    border: solid 1px #ff5b23;
    border-radius: 4px;
    background-color: rgba(255, 91, 35, 0.2);
    color: #333;
    font-weight: bold;
  }

  .timeline-calendar .fc-title-wrap {
    display: flex;
    justify-content: center;
  }

  .timeline-calendar .fc-timeline-event.not_clickable {
    cursor: default;
  }

  .timeline-calendar .fc-timeline-event.not_clickable:hover {
    z-index: 1 !important;
  }

  .timeline-calendar .fc-timeline-event.all_day .fc-time {
    display: none;
  }

  .timeline-calendar .fc-timeline-event::after {
    content: '';
    position: absolute;
    top: 0;
    right: 10px;
    width: 20px;
    height: 13px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .timeline-calendar .fc-timeline-event.video,
  .timeline-calendar .fc-timeline-event.phone,
  .timeline-calendar .fc-timeline-event.videoroom,
  .timeline-calendar .fc-timeline-event.in_person {
    border: solid 1px #3f43ad;
    border-radius: 4px;
    background-color: #d9d2ee;
    background-position: center;
    background-repeat: no-repeat;
    white-space: nowrap;
  }

  .timeline-calendar .fc-timeline-event.video {
    background-image: url(~@assets/imgs/assignment_types/availability/video_icon_blue.svg);
    background-size: 14px auto;
  }

  .timeline-calendar .fc-timeline-event.videoroom {
    background-image: url(~@assets/imgs/assignment_types/availability/videoroom_icon_blue.svg);
    background-size: 12px auto;
  }

  .timeline-calendar .fc-timeline-event.phone {
    background-image: url(~@assets/imgs/assignment_types/availability/phone_icon_blue.svg);
    background-size: auto 12px;
  }

  .timeline-calendar .fc-timeline-event.in_person {
    background-image: url(~@assets/imgs/assignment_types/availability/inperson_icon_blue.svg);
    background-size: auto 14px;
  }

  .timeline-calendar .fc-scrollgrid tr:last-of-type > td {
    box-shadow: 0 -1px 0 inset #ddd;
  }
</style>
